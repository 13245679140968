import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: () => import('../views/layout.vue'),
  redirect: {
    path: '/home'
  },
  children: [
    {
      path: '/home',
      name: 'home',
      component: () => import('../views/home.vue')
    },
    {
      path: '/home/:anchor',
      name: 'homeAnchor',
      component: () => import('../views/home.vue')
    },
    {
      path: '/business/:type',
      name: 'businessDetails',
      component: () => import('../views/businessDetails.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/about.vue')
    },
    {
      path: '/about/:anchor',
      name: 'about',
      component: () => import('../views/about.vue')
    },
    {
      path: '/notice',
      name: 'notice',
      component: () => import('../views/notice.vue')
    },
    {
      path: '/notice/:anchor',
      name: 'noticeAnchor',
      component: () => import('../views/notice.vue')
    },
    {
      path: '/notice-detail/:id',
      name: 'noticeDetail',
      component: () => import('../views/noticeDetail.vue')
    },
    {
      path: '/download',
      name: 'download',
      component: () => import('../views/download.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/contact.vue')
    },
    {
      path: '/err',
      name: 'error',
      component: () => import('../views/errPage.vue')
    },
    {
      path: '/404',
      name: '404',
      component: () => import('../views/404Page.vue')
    }
  ]
},
{
  path: '*',
  name: '404',
  component: () => import('../views/404Page.vue')
}]
const router = new VueRouter({
  // mode: 'history',
  // srcollBehavior(to){
  //   if(to.hash){
  //     return {
  //       selector:to.hash
  //     }
  //   }
  // },
  // base: process.env.BASE_URL,
  routes
})

export default router
