import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    registerNumber: 0,
    gateNumber: 0
  },
  getters: {
  },
  mutations: {
    changeRegisterNumber(state, n) {
      state.registerNumber = n
    },
    changeGateNumber(state, n) {
      state.gateNumber = n
    }
  },
  actions: {
    changeRegisterNumber(context, n) {
      context.commit('changeRegisterNumber', n)
    },
    changeGateNumber(context, n) {
      context.commit('changeGateNumber', n)
    }
  },
  modules: {
  }
})
